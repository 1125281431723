@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Primary */
  --color-primary-50: #eaeaee;
  --color-primary-100: #d3d4dc;
  --color-primary-200: #a7a9b9;
  --color-primary-300: #7b7d95;
  --color-primary-400: #4f5272;
  --color-primary-500: #04182b;
  --color-primary-600: #1a1d3b;
  --color-primary-700: #121428;
  --color-primary-800: #090a14;
  --color-primary-900: #040408;
  --color-primary-1000: #294f5d;
  --color-primary-1100: #04182b;

  /* Secondary */
  --color-secondary-50: #faf8f2;
  --color-secondary-100: #f4efe5;
  --color-secondary-200: #e9e0ca;
  --color-secondary-300: #ddd0b0;
  --color-secondary-400: #d2c195;
  --color-secondary-500: #c7b17b;
  --color-secondary-600: #95855c;
  --color-secondary-700: #64593e;
  --color-secondary-800: #322c1f;
  --color-secondary-900: #14120c;

  --color-white: #fff;
  --color-gray-1000: #eef1f6;
  --color-gray-1100: #a7a9b9;
  --color-gray-2000: #6b7280;

  --color-red: #c77979;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-list {
  border-radius: 12px;
}
